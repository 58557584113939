import * as React from 'react';

import { Link } from 'gatsby';

import Layout from '../components/layout.js';

export default function AboutPage() {
  return (
    <Layout
      title="About All Corvette Models"
      description="All Corvette Models blog is all about Fancy Corvettes."
    >
      <h1>About my blog</h1>
      <hr />
      <p>
        I enjoy writing about Corvette sports cars because they are one of the
        few two-seater sports cars that are considered as a good everyday car
        and such a beloved car. Its generous trunk space and easy-to-use
        controls make it an enjoyable driving experience. The C7 ZR1 had a
        noticeable "old man's toy" appeal, and it was also the first Chevrolet
        model to break the $50 million mark. The roof panel is removable, so you
        can easily access the trunk and storage space when you need it.
      </p>{' '}
      <p>
        The C5 Corvette was completely redesigned from the ground up, and
        production began in 1996, though it was delayed a year due to production
        problems. It was a radical redesign, with many innovative concepts and
        new manufacturing breakthroughs. It achieved a top speed of 176 mph and
        was considered a vast improvement over the C4 design.
      </p>{' '}
      <p>
        Corvette is one of the most popular sports cars on the road. The C7 and
        Z06 are my favorites, and they have been around for decades. The newest
        C8 generation is even more luxurious than its predecessors. However, the
        Corvette Z06 is a true supercar, with a naturally aspirated V8 engine
        and an eight-speed DCT transmission. There are many reasons to like the
        Corvette, and I hope this blog will inspire you to buy one for yourself.
      </p>{' '}
      <Link to="/">&larr; Back to home</Link>
    </Layout>
  );
}
